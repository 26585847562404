import { backwardCompatibleMembraneHousings } from '../../../../../../../feature/projections/hooks/use-available-membrane-housings';
import { ProjectionClass } from '../../../../../../application/resources/projection/v2/single';
import {
  calcModuleNameFromCalculations,
  oldMembraneValueToNewMembraneValue,
} from '../../../../../../v2/feature/projection/business/projection/membrane-module';

// helper functions
export const calculationValueById = (id: string, projection: ProjectionClass) => {
  const data = [
    // combine
    ...(projection.record.inputs || []),
    ...(projection.record.outputs || []),
  ];

  let parsedData = data.find((item) => item.id === id);
  if (parsedData && parsedData.id === 'ceb_1_a_rinsing_duration') {
    parsedData.name = 'CEB1A/2A Rinse Duration';
  }
  if (parsedData && parsedData.id === 'ceb_1_b_rinsing_duration') {
    parsedData.name = 'CEB1B/2B Rinse Duration';
  }

  // pxx-1494
  if (parsedData?.id === 'flow_base') {
    if (parsedData?.values[0] === 1) {
      parsedData.values[0] = 'Permeate Production';
    } else if (parsedData?.values[0] === 0) {
      parsedData.values[0] = 'Feed flow';
    }
  }

  // pxx-1494 also found the membrane_element being set wrongly
  if (parsedData?.id === 'membrane_element') {
    parsedData.values[0] = oldMembraneValueToNewMembraneValue(String(parsedData.values[0]));
  }

  // sigh... pxx-1392 && pxx-1393
  if (parsedData?.id === 'membrane_housing') {
    // which membrane element is picked?
    const selectedMembrane = data.find((i) => i.id === 'membrane_element')?.values[0];
    // update value for OLD => NEW
    parsedData.values[0] = backwardCompatibleMembraneHousings(parsedData.values[0], selectedMembrane);
  }

  return parsedData;
};
