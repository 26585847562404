/*

  By Code.Rehab

*/

import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation, useParams } from 'react-router';

import PrintPreviewHeader from './PrintPreviewHeader';
import PrintPreviewFooter from './PrintPreviewFooter';
import {
  AquaflexDesignResultImage,
  AquaflexDesignResultImageLegend,
  AquaflexDesignResultSettings,
  parseData,
} from '../../../__old__/view/pages/projection/partials/results-page/aquaflex';
import {
  XigaDesignResultImage,
  XigaDesignResultImageLegend,
  XigaDesignResultSettings,
} from '../../../__old__/view/pages/projection/partials/results-page/xiga-new';

import { ProjectionContext } from '../../../__old__/v2/feature/projection/context/projection-context';
import { ProjectionClass } from '../../../__old__/application/resources/projection/v2/single';
import { usePrintPreview } from '../hooks/usePrintPreview';
import { Typography, Grid, Theme } from '@mui/material';
import {
  XLineDesignResultImage,
  XLineDesignResultImageLegend,
  XLineDesignResultSettings,
} from '../../../__old__/view/pages/projection/partials/results-page/xline-new';
import {
  AquaflexHSDesignResultImage,
  AquaflexHSDesignResultImageLegend,
  AquaflexHSDesignResultSettings,
} from '../../../__old__/view/pages/projection/partials/results-page/aquaflex-hs';
import { ProjectionDescription } from '../../projections/components/projection-description';
import SystemConfigurationDutyStandby from '../../../__old__/view/pages/projection/partials/results-page/system-config-duty-standby';
import { ProjectionDataTable } from '../../../__old__/view/pages/projection/partials/projection-data-table';
import { calculationValueById } from '../../../__old__/view/pages/projection/partials/results-page/helpers/calculation-value-by-id';

const stage = () => {
  const domain = window.location.hostname;

  if (domain.includes('qa.')) {
    return 'QA';
  } else if (domain.includes('uat.')) {
    return 'UAT';
  } else if (domain.includes('localhost')) {
    return 'DEV';
  } else {
    return '';
  }
};

// styles
const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    width: '21cm',
    fontFamily: "'Barlow', Arial, Helvetica, FreeSans, sans-serif",
  },
  devStageWarning: {
    position: 'absolute',
    top: '13cm',
    left: '33%',
    transform: 'rotate(345deg)',
    padding: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: 'rgba(255,255,255,0.7)',
    background: 'rgba(255,0,0,0.5)',
    fontSize: '6rem !important',
    fontWeight: 700,
    borderRadius: 10,
    zIndex: 456456,
  },
  header: {
    position: 'fixed',
    top: 0,
    width: '21cm',
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid lightgrey',
    zIndex: 2,
  },
  headerSpacer: {
    width: '21cm',
  },
  pageBreak: {
    position: 'relative',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    breakAfter: 'page',
    pageBreakAfter: 'always',
    zIndex: 1,
    paddingBottom: 5,
    paddingTop: 5,
  },
  tableBody: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '21cm',
    paddingTop: theme.spacing(0.5),
    borderTop: '1px solid lightgrey',
    fontSize: '6px !important',
  },
  footerSpacer: {
    width: '21cm',
  },
  guidelines: {
    fontSize: '12px !important',
    '& p:first-child': {
      // '&:first-of-type': {
      marginBlockStart: 0,
    },
    '& p:last-child': {
      marginBlockEnd: 0,
    },
  },
  guidelinesHeader: {
    marginBottom: theme.spacing(1),
  },
  topSpacing: {
    marginTop: theme.spacing(1),
  },
  noPageBreakWithin: {
    breakInside: 'avoid',
  },
}));

type OwnProps = {};

const useQueries = () => new URLSearchParams(useLocation().search);

export const PrintPreview: React.FC<OwnProps> = (props) => {
  document.body.style.fontSize = '12px';

  // get url parameters
  const { projectionid, projectId } = useParams<{
    projectId: string;
    projectionid: string;
  }>();

  const headerRef = React.useRef<any>(null);
  const footerRef = React.useRef<any>(null);
  const headerSpaceRef = React.useRef<any>(null);
  const footerSpaceRef = React.useRef<any>(null);
  const headerSpace2Ref = React.useRef<any>(null);
  const footerSpace2Ref = React.useRef<any>(null);
  const headerSpace3Ref = React.useRef<any>(null);
  const footerSpace3Ref = React.useRef<any>(null);
  const headerSpace4Ref = React.useRef<any>(null);
  const footerSpace4Ref = React.useRef<any>(null);
  const headerSpace5Ref = React.useRef<any>(null);
  const footerSpace5Ref = React.useRef<any>(null);
  // query params
  const queries = useQueries();

  // pageReady
  const [pageReady, setReady] = React.useState(false);

  // hooks
  const { loading, project, projection, fetchData, projectionClass } = usePrintPreview(
    projectId,
    projectionid,
    queries.get('token') || '',
    queries.get('version') || undefined
  );

  // Check if there are calculation value input with isSUggested
  const hasSuggestedValue = projection?.inputs.find((calculationValue) => calculationValue.isSuggested);

  // style
  const classes = (useStyles as any)();

  // load project & projection is not set
  React.useEffect(() => {
    if (!project && !projection && !loading) {
      fetchData();
    }
  }, [loading, project, projection, fetchData]);

  React.useEffect(() => {
    let timer: any;
    let header = headerRef.current;
    let header1 = headerSpaceRef.current;
    let header2 = headerSpace2Ref.current;
    let header3 = headerSpace3Ref.current;
    let header4 = headerSpace4Ref.current;
    let header5 = headerSpace5Ref.current;
    let footer = footerRef.current;
    let footer1 = footerSpaceRef.current;
    let footer2 = footerSpace2Ref.current;
    let footer3 = footerSpace3Ref.current;
    let footer4 = footerSpace4Ref.current;
    let footer5 = footerSpace5Ref.current;

    if (header) {
      let height = header.offsetHeight; // IF OVERFLOW HAPPENS REMOVE + 8
      if (header1) {
        header1.style.height = height + 'px';
        header1.style.lineHeight = height + 'px';
      }
      if (header2) {
        header2.style.height = height + 'px';
        header2.style.lineHeight = height + 'px';
      }
      if (header3) {
        header3.style.height = height + 'px';
        header3.style.lineHeight = height + 'px';
      }
      if (header4) {
        header4.style.height = height + 'px';
        header4.style.lineHeight = height + 'px';
      }
      if (header5) {
        header5.style.height = height + 'px';
        header5.style.lineHeight = height + 'px';
      }
    }
    if (footer) {
      let height = footer.offsetHeight;
      if (footer1) {
        footer1.style.height = height + 'px';
        footer1.style.lineHeight = height + 'px';
      }
      if (footer2) {
        footer2.style.height = height + 'px';
        footer2.style.lineHeight = height + 'px';
      }
      if (footer3) {
        footer3.style.height = height + 'px';
        footer3.style.lineHeight = height + 'px';
      }
      if (footer4) {
        footer4.style.height = height + 'px';
        footer4.style.lineHeight = height + 'px';
      }
      if (footer5) {
        footer5.style.height = height + 'px';
        footer5.style.lineHeight = height + 'px';
      }

      // footer height has been set, give puppeteer some time to render, then trigger the toPdf function
      timer = setTimeout(() => setReady(true), 100);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  React.useEffect(() => {
    let a = setTimeout(() => setReady(true), 7000); // delay 7 seconds
    return () => {
      clearTimeout(a);
    };
  }, []);

  // if no projection or anything, show no content
  if (!projection || !project || !projectionClass || loading) {
    return <div style={{ width: '100%', textAlign: 'center' }}>Loading print preview, one moment please</div>;
  }

  const projectionGuidelines = projection.guidelines.join(' ').split(/<p>(_____+|-----+)<\/p>/gim);

  // return content
  return (
    <>
      {/* Projection Context Provider */}
      <ProjectionContext.Provider value={projectionClass || new ProjectionClass()}>
        <div className={classes.pageContainer}>
          <div ref={headerRef} className={classes.header}>
            <PrintPreviewHeader project={project} projection={projection} />
            {(stage() === 'QA' || stage() === 'UAT' || stage() === 'DEV') && (
              <div className={classes.devStageWarning}>{stage()}</div>
            )}
          </div>

          <table className={classes.pageBreak}>
            <thead>
              <tr>
                <td>
                  <div ref={headerSpaceRef} className={classes.headerSpacer}>
                    &nbsp;
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableBody}>
                  {(projection.solution?.id === 'aquaflex-new' || projection.solution?.id === 'aquaflex') && (
                    <div>
                      <AquaflexDesignResultImage handleChange={() => {}} print egu={queries.get('egu') || 'metric'} />
                      <Grid container spacing={1} className={classes.topSpacing}>
                        <Grid item xs={6}>
                          <SystemConfigurationDutyStandby
                            solution="aquaflex-new"
                            inputs={projection?.inputs || []}
                            outputs={projection?.outputs || []}
                            print={true}
                            egu={queries.get('egu') || 'metric'}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AquaflexDesignResultImageLegend print egu={queries.get('egu') || 'metric'} />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            title={'Projection Inputs'}
                            print={true}
                            data={parseData([
                              calculationValueById('water_source', projectionClass),
                              calculationValueById('flow', projectionClass),
                              calculationValueById('flow_base', projectionClass),
                              calculationValueById('design_turbidity', projectionClass),
                              calculationValueById('membrane_element', projectionClass),
                              calculationValueById('membrane_housing', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Main feed water parameters'}
                            data={parseData([
                              calculationValueById('turbidity', projectionClass),
                              calculationValueById('tss', projectionClass),
                              calculationValueById('toc', projectionClass),
                              calculationValueById('cod', projectionClass),
                              calculationValueById('color', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Additional water parameters'}
                            data={parseData([
                              calculationValueById('temperature', projectionClass),
                              calculationValueById('alcalinity', projectionClass),
                              calculationValueById('ph', projectionClass),
                              calculationValueById('tds', projectionClass),
                            ])}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {projection.solution?.id === 'aquaflex-hs' && (
                    <div>
                      <AquaflexHSDesignResultImage handleChange={() => {}} print egu={queries.get('egu') || 'metric'} />
                      <Grid container spacing={1} className={classes.topSpacing}>
                        <Grid item xs={6}>
                          <SystemConfigurationDutyStandby
                            solution="aquaflex-hs"
                            inputs={projection?.inputs || []}
                            outputs={projection?.outputs || []}
                            print={true}
                            egu={queries.get('egu') || 'metric'}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AquaflexHSDesignResultImageLegend print egu={queries.get('egu') || 'metric'} />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            title={'Projection Inputs'}
                            print={true}
                            data={parseData([
                              calculationValueById('water_source', projectionClass),
                              calculationValueById('ahs_bod_inlet', projectionClass),
                              calculationValueById('flow', projectionClass),
                              calculationValueById('flow_base', projectionClass),
                              calculationValueById('design_turbidity', projectionClass),
                              calculationValueById('membrane_element', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Main feed water parameters'}
                            data={parseData([
                              calculationValueById('turbidity', projectionClass),
                              calculationValueById('tss', projectionClass),
                              calculationValueById('toc', projectionClass),
                              calculationValueById('cod', projectionClass),
                              calculationValueById('bod', projectionClass),
                              calculationValueById('color', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Additional water parameters'}
                            data={parseData([
                              calculationValueById('temperature', projectionClass),
                              calculationValueById('alcalinity', projectionClass),
                              calculationValueById('ph', projectionClass),
                              calculationValueById('tds', projectionClass),
                            ])}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {projection.solution?.id === 'xiga' && (
                    <div>
                      <XigaDesignResultImage handleChange={() => {}} print egu={queries.get('egu') || 'metric'} />
                      <Grid container spacing={1} className={classes.topSpacing}>
                        <Grid item xs={6}>
                          <SystemConfigurationDutyStandby
                            solution="xiga"
                            inputs={projection?.inputs || []}
                            outputs={projection?.outputs || []}
                            print={true}
                            egu={queries.get('egu') || 'metric'}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <XigaDesignResultImageLegend print egu={queries.get('egu') || 'metric'} />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            title={'Projection Inputs'}
                            print={true}
                            data={parseData([
                              calculationValueById('water_source', projectionClass),
                              calculationValueById('flow', projectionClass),
                              calculationValueById('flow_base', projectionClass),
                              calculationValueById('design_turbidity', projectionClass),
                              calculationValueById('membrane_element', projectionClass),
                              calculationValueById('membrane_housing', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Main feed water parameters'}
                            data={parseData([
                              calculationValueById('turbidity', projectionClass),
                              calculationValueById('tss', projectionClass),
                              calculationValueById('toc', projectionClass),
                              calculationValueById('cod', projectionClass),
                              calculationValueById('color', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Additional water parameters'}
                            data={parseData([
                              calculationValueById('temperature', projectionClass),
                              calculationValueById('alcalinity', projectionClass),
                              calculationValueById('ph', projectionClass),
                              calculationValueById('tds', projectionClass),
                            ])}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {projection.solution?.id === 'xline' && (
                    <div>
                      <XLineDesignResultImage handleChange={() => {}} print egu={queries.get('egu') || 'metric'} />
                      <Grid container spacing={1} className={classes.topSpacing}>
                        <Grid item xs={6}>
                          <SystemConfigurationDutyStandby
                            solution="xline"
                            inputs={projection?.inputs || []}
                            outputs={projection?.outputs || []}
                            print={true}
                            egu={queries.get('egu') || 'metric'}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <XLineDesignResultImageLegend print egu={queries.get('egu') || 'metric'} />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            title={'Projection Inputs'}
                            print={true}
                            data={parseData([
                              calculationValueById('water_source', projectionClass),
                              calculationValueById('flow', projectionClass),
                              calculationValueById('flow_base', projectionClass),
                              calculationValueById('design_turbidity', projectionClass),
                              calculationValueById('membrane_element', projectionClass),
                              calculationValueById('membrane_housing', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Main feed water parameters'}
                            data={parseData([
                              calculationValueById('turbidity', projectionClass),
                              calculationValueById('tss', projectionClass),
                              calculationValueById('toc', projectionClass),
                              calculationValueById('cod', projectionClass),
                              calculationValueById('color', projectionClass),
                            ])}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ProjectionDataTable
                            egu={queries.get('egu') || 'metric'}
                            print={true}
                            title={'Additional water parameters'}
                            data={parseData([
                              calculationValueById('temperature', projectionClass),
                              calculationValueById('alcalinity', projectionClass),
                              calculationValueById('ph', projectionClass),
                              calculationValueById('tds', projectionClass),
                            ])}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </td>
              </tr>

              {hasSuggestedValue ? (
                <tr>
                  <td width="100%" style={{ fontStyle: 'italic' }}>
                    *Assumed value: It is recommended to analyze actual water quality data. Deviations between actual
                    and assumed values may require a design review and adjustments.
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div ref={footerSpaceRef} className={classes.footerSpacer}>
                    &nbsp;
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>

          <table className={classes.pageBreak}>
            <thead>
              <tr>
                <td>
                  <div ref={headerSpace2Ref} className={classes.headerSpacer}>
                    &nbsp;
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableBody}>
                  {(projection.solution?.id === 'aquaflex-new' || projection.solution?.id === 'aquaflex') && (
                    <>
                      <div>
                        <Grid item xs={12}>
                          <ProjectionDescription print handleChange={() => {}} egu={queries.get('egu') || 'metric'} />
                        </Grid>
                      </div>
                      <div className={classes.noPageBreakWithin} style={{ breakInside: 'avoid' }}>
                        <AquaflexDesignResultSettings print egu={queries.get('egu') || 'metric'} />
                      </div>
                    </>
                  )}
                  {projection.solution?.id === 'aquaflex-hs' && (
                    <>
                      <div>
                        <Grid item xs={12}>
                          <ProjectionDescription print handleChange={() => {}} egu={queries.get('egu') || 'metric'} />
                        </Grid>
                      </div>
                      <div className={classes.noPageBreakWithin} style={{ breakInside: 'avoid' }}>
                        <AquaflexHSDesignResultSettings print egu={queries.get('egu') || 'metric'} />
                      </div>
                    </>
                  )}
                  {projection.solution?.id === 'xiga' && (
                    <>
                      <div>
                        <Grid item xs={12}>
                          <ProjectionDescription print handleChange={() => {}} egu={queries.get('egu') || 'metric'} />
                        </Grid>
                      </div>
                      <div className={classes.noPageBreakWithin} style={{ breakInside: 'avoid' }}>
                        <XigaDesignResultSettings print egu={queries.get('egu') || 'metric'} />
                      </div>
                    </>
                  )}
                  {projection.solution?.id === 'xline' && (
                    <>
                      <div>
                        <Grid item xs={12}>
                          <ProjectionDescription print handleChange={() => {}} egu={queries.get('egu') || 'metric'} />
                        </Grid>
                      </div>
                      <div className={classes.noPageBreakWithin} style={{ breakInside: 'avoid' }}>
                        <XLineDesignResultSettings print egu={queries.get('egu') || 'metric'} />
                      </div>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div ref={footerSpace2Ref} className={classes.footerSpacer}>
                    &nbsp;
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>

          {/* // Sorry Refs need to be different and manually set for each of the guideline pages */}
          {projectionGuidelines.map((guidelinePart, index) => {
            // hide the lines with:
            const matched = /(_____+|-----+)/gim.test(guidelinePart);

            const isLast = index + 1 === projectionGuidelines.length;

            // normal line
            if (guidelinePart && !matched) {
              return (
                <table
                  className={classes.pageBreak}
                  style={{ pageBreakAfter: isLast ? 'auto' : 'initial', breakAfter: isLast ? 'auto' : 'initial' }}
                  key={index}
                >
                  <thead>
                    <tr>
                      <td className={classes.tableBody}>
                        <div ref={index === 0 ? headerSpace3Ref : headerSpace4Ref} className={classes.headerSpacer}>
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody className={classes.tableBody}>
                    <tr>
                      <td>
                        {/* <div> */}
                        {index === 0 && (
                          <Typography variant="h5" className={classes.guidelinesHeader}>
                            Guidelines
                          </Typography>
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: guidelinePart,
                          }}
                          className={classes.guidelines}
                        ></div>
                        {/* </div> */}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <div ref={index === 0 ? footerSpace3Ref : footerSpace4Ref} className={classes.footerSpacer}>
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              );
            } else {
              return null;
            }
          })}
          <div ref={footerRef} className={classes.footer}>
            <PrintPreviewFooter printedBy={queries.get('printUser')} version={queries.get('xpertVersion') || '1.0.0'} />
          </div>
          {pageReady && <span id="puppeteer-trigger-image-loaded"></span>}
        </div>
      </ProjectionContext.Provider>
    </>
  );
};
export default PrintPreview;
