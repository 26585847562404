import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Amplify } from '@aws-amplify/core';
import { amplify } from './__old__/config/amplify';

import { I18nextProvider } from 'react-i18next';
import { application } from './__old__/application/application';
import { CacheProvider } from './__old__/v2/feature/cache/context';
import { LoadUserContextData } from './__old__/v2/feature/user/business/user-context';
import { Routes } from './__old__/view/routes';
import { PermissionProvider } from './feature/auth/auth-permissions';
import { AuthProvider } from './feature/auth/auth-provider';

import { PentairApp } from '@pentair/react-mui-5';
import { HelmetProvider } from 'react-helmet-async';
import { QuickProfileSwitcher } from './feature/debug-mode/quick-profile-switcher';
import { SolutionContextProvider } from './feature/proposals/context/solution-context';
import { VersionContextProvider } from './feature/xpert-version/context/version-context';
import i18next from './i18next';

// adding a comment here, to retrigger a new pipeline

Amplify.configure(amplify);

export const AppVersion = process.env.APPVERSION || '1.0.1';

const userInteractor = application.interactor.user;

const isLoggedIn = () => {
  try {
    return userInteractor.currentUser ? true : false;
  } catch (error) {
    return false;
  }
};

export const App: React.FC = () => {
  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18next}>
        <PentairApp>
          <Router>
            <AuthProvider>
              <PermissionProvider>
                <VersionContextProvider
                  disabled={window.location.pathname.includes(
                    'print/project/'
                  )} /* disable the versioncontext when printing (avoids errors) */
                >
                  <CacheProvider>
                    {isLoggedIn() && <LoadUserContextData />}
                    <SolutionContextProvider>
                      <Routes />
                      <QuickProfileSwitcher />
                    </SolutionContextProvider>
                  </CacheProvider>
                </VersionContextProvider>
              </PermissionProvider>
            </AuthProvider>
          </Router>
        </PentairApp>
      </I18nextProvider>
    </HelmetProvider>
  );
};

export default App;
// Stub
