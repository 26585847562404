/*

  By Code.Rehab

*/

import gql from 'graphql-tag';

export const GetProjectAndProjectionQuery = gql`
  query Projection($projectId: ID!, $projectionid: ID!, $version: String) {
    Project(id: $projectId) {
      # id
      # owner {
      #   id
      #   attributes {
      #     given_name
      #     family_name
      #   }
      # }
      # name
      # destination {
      #   id
      #   name
      # }
      # updatedAt
      # createdAt
      # updatedBy {
      #   id
      #   attributes {
      #     given_name
      #     family_name
      #   }
      # }
      # opportunity {
      #   opportunityNumber
      #   account {
      #     name
      #   }
      # }
      # awaitingOpportunity
      id
      name
      destination
      updatedAt
      createdAt
      updatedBy {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      opportunity {
        opportunityNumber
        account {
          name
        }
      }
      awaitingOpportunity
    }
    Projection(id: $projectionid, version: $version) {
      id
      name
      note
      version
      calcState
      description
      guidelines
      updatedAt
      versions {
        versionId
        lastModified
        designedBy {
          id
          attributes {
            given_name
            family_name
          }
        }
        approvedBy {
          id
          attributes {
            given_name
            family_name
          }
        }
        isLatest
        note
      }
      project {
        id
        name
      }
      advisedInputs {
        id
        name
        values
        recommendedValues
        unit
        unitOptions
        valueOptions {
          id
          label
        }
        disabled
        error
        category
        decimals
        calculation_field
        isSuggested
      }
      inputs {
        id
        name
        values
        recommendedValues
        unit
        unitOptions
        valueOptions {
          id
          label
        }
        disabled
        error
        category
        decimals
        calculation_field
        isSuggested
      }
      outputs {
        id
        name
        values
        recommendedValues
        unit
        unitOptions
        valueOptions {
          id
          label
        }
        disabled
        error
        category
        decimals
        calculation_field
        isSuggested
      }
      createdBy {
        id
        attributes {
          given_name
          family_name
          account_name
        }
      }
      updatedBy {
        id
        attributes {
          given_name
          family_name
        }
      }
      approver {
        id
        attributes {
          given_name
          family_name
        }
      }
      solution {
        id
        name
        oneliner
        description
        image
        techImage
        techImageMap {
          type
          icon
          title
          description
          location
          number
          info_label
          values
          dialog
          key
          rotation
          content
          height
          width
          arrow_position
          splitKeyValue
          centeredText
        }
      }
    }
  }
`;
