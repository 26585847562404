import * as React from 'react';
import { toFixedIfNecessary } from '../../../application/resources/helper/to-fixed';
import { ProjectionRecord } from '../../../application/resources/projection/projection';
import { ProjectionClass } from '../../../application/resources/projection/v2/single';
import { CalculationValue, Inputs, ModuleTypeName, State } from '../../feature/solution/types/record';
import { keepDuplicates, removeDuplicates } from './remove-duplicates';

const calcModuleName = (module: string, solution: string) => {
  if (solution === 'xiga') {
    if (module === 'XF64') {
      return 'XIGA 64';
    } else if (module === 'XF55') {
      return 'XIGA 55';
    } else if (module === 'HFS60') {
      return 'HFS 60 Silica H';
    } else if (module === 'XF75') {
      return 'XF75';
    } else if (module === 'XF53') {
      return 'XF53';
    } else if (module === 'XF40') {
      return 'XIGA 40';
    } else if (module === 'Custom') {
      return 'Custom';
    }
  } else {
    if (module === 'XF64') {
      return 'Aquaflex 64';
    } else if (module === 'XF55') {
      return 'Aquaflex 55';
    } else if (module === 'HFS60') {
      return 'HFS 60 Silica V';
    } else if (module === 'XF75') {
      // return module;
    } else if (module === 'XF40C') {
      return 'Aquaflex 40';
    } else if (module === 'Custom') {
      return 'Custom';
    }
  }
  return 'XF75';
};

export const runCalculation = (
  projection: ProjectionClass,
  setRunningCalc: (value: React.SetStateAction<boolean>) => void,
  setActiveProcess: (value: React.SetStateAction<string>) => void,
  onClose: () => void,
  onChange: (key: keyof ProjectionRecord, value: any) => void,
  setSavedRecordState: () => void,
  setData: (value: React.SetStateAction<CalculationValue[]>) => void,
  dataFilterKeys: string[],
  data: CalculationValue[],
  setError?: (message: any) => void
) => {
  if (setError) {
    setError(undefined);
  }
  setRunningCalc(true);
  setActiveProcess('Preparing / Uploading data to cloud..');

  let parsedState: State | undefined;
  try {
    parsedState = JSON.parse(projection.record?.calcState || '');
  } catch (err) {
    console.error('JSON.parse() error:', err);
  }

  if (!parsedState) {
    setError && setError('Your projection inputs are corrupted, please contact your accountmanager');
    setRunningCalc(false);
    return;
  }

  const state: State = parsedState;

  const oldCalcInputs: Inputs = state.user.inputs;

  const newCalcInputs: Partial<Inputs> = (data || [])
    .filter(
      ({ calculation_field, values: [value] }) => calculation_field && value !== state.user.inputs[calculation_field]
    )
    .reduce((p, { calculation_field, values: [value], decimals, valueOptions, unit }) => {
      let parsedValue: any = value;

      if (decimals) {
        parsedValue = toFixedIfNecessary(String(value), parseFloat(String(decimals || '0')));
      }

      if (value === 'null') {
        parsedValue = '';
      }

      if (valueOptions && valueOptions.find((option) => option.id === 'true')) {
        parsedValue = value === 'true' ? true : false;
      }

      // this fixes bug pxx-1338
      if (calculation_field === 'all_cap_based_on') {
        if (value === 'Permeate Production') {
          parsedValue = 1;
        } else {
          parsedValue = 0;
        }
      }
      // end fix

      // moved below the toFixedIfNecessary(), for some reason this reset the value of the all_module_name to the wrong name
      // bug reported in pxx-1581
      if (calculation_field === 'all_module_name') {
        parsedValue = calcModuleName(value as string, projection.record.solution?.id || 'aquaflex') as ModuleTypeName;
      }

      p[calculation_field] = parsedValue;

      return p;
    }, {});

  // this does not remove duplicates, its removes key+value from the newCalcInputs object,
  // if they exist in the olCalcInputs object and returns a new object without them
  const calculationInputs = removeDuplicates(newCalcInputs, oldCalcInputs);

  // console.log('newCalcInputs, oldCalcInputs, calculationInputs', { newCalcInputs, oldCalcInputs, calculationInputs });

  // pxx-1340
  /*
    "chm_chemical_agent_1_ceb1a_concentration": 200,
    "chm_chemical_agent_2_ceb1a_concentration": 435,
    "chm_chemical_agent_1_ceb1b_concentration": 369,
    "chm_chemical_agent_2_ceb1b_concentration": 0,
    "chm_chemical_agent_1_ceb2a_concentration": 0,
    "chm_chemical_agent_2_ceb2a_concentration": 0,
    "chm_chemical_agent_1_ceb2b_concentration": 0,
    "chm_chemical_agent_2_ceb2b_concentration": 0
  */

  // if( !("chm_chemical_agent_1_ceb1a_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_1_ceb1a_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_2_ceb1a_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_2_ceb1a_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_1_ceb1b_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_1_ceb1b_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_2_ceb1b_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_2_ceb1b_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_1_ceb2a_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_1_ceb2a_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_2_ceb2a_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_2_ceb2a_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_1_ceb2b_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_1_ceb2b_concentration"] = 0;
  // }
  // if( !("chm_chemical_agent_2_ceb2b_concentration" in calculationInputs) ) {
  //   calculationInputs["chm_chemical_agent_2_ceb2b_concentration"] = 0;
  // }

  // end pxx-1340

  setActiveProcess('Running calculations..');

  projection
    .runCalculation(JSON.stringify(calculationInputs), projection.record.calcState || '')
    .then((inputs) => {
      setActiveProcess('Processing calculation result..');
      setTimeout(() => {
        if (inputs && inputs.message) {
          if (setError) {
            setError('Error: ' + inputs.message);
          }
          setRunningCalc(false);
          return;
        }

        if (inputs && inputs.state) {
          const calculationResult: State = JSON.parse(inputs.state);

          onChange('calcState', inputs.state);

          let projectionInputs = projection.record.inputs || [];
          let projectionOutputs = projection.record.outputs || [];

          for (const [key, value] of Object.entries(calculationResult.user.inputs)) {
            // Find index of the projectionValue we want to change.
            const calcValIndex = projectionInputs.findIndex((input) => input.calculation_field === key);

            // Update value in our projectionValues array
            if (projectionInputs[calcValIndex]) {
              let parsedVal: any = String(value);

              if (parseFloat((projectionInputs[calcValIndex].decimals || '-1') as string) >= 0) {
                parsedVal = String(
                  toFixedIfNecessary(
                    String(value),
                    parseFloat((projectionInputs[calcValIndex].decimals || '-1') as string)
                  )
                );
              } else if (projectionInputs[calcValIndex].decimals) {
                parsedVal = String(value);
              }

              if (projectionInputs[calcValIndex].valueOptions?.find((option) => option.id === 'true')) {
                parsedVal = String(value);
              }

              if (projectionInputs[calcValIndex].valueOptions?.find((option) => option.id === 'null')) {
                if (value === '') {
                  parsedVal = String('null');
                }
              }

              projectionInputs[calcValIndex].values = [String(parsedVal)];
            } else {
              // pxx-1260
              if (key === 'membrane_elements_standby_philosophy') {
                projectionInputs.push({
                  id: 'membrane_elements_standby_philosophy',
                  name: 'Standby Philosophy',
                  values: [String(value)],
                  unit: '',
                  recommendedValues: ['per-backwash-train'],
                  valueOptions: [
                    {
                      id: 'per-backwash-train',
                      label: 'Unit(s) per train',
                    },
                    {
                      id: 'common',
                      label: 'Common Standby',
                    },
                  ],
                  calculation_field: 'membrane_elements_standby_philosophy',
                });
              }
              if (key === 'membrane_elements_standby_units') {
                projectionInputs.push({
                  id: 'membrane_elements_standby_units',
                  name: 'Total Standby Units',
                  values: [String(value) || '0'],
                  recommendedValues: ['0'],
                  unit: 'number',
                  calculation_field: 'membrane_elements_standby_units',
                });
              }
            }

            // Update value in our projectionValues array
            if (projectionInputs[calcValIndex]) {
              let parsedVal: any = value;
              if (key === 'all_module_name') {
                if ((projection.record.solution?.id || '') === 'xiga') {
                  if (parsedVal === 'XIGA 64') {
                    parsedVal = 'XF64';
                  } else if (parsedVal === 'XIGA 55') {
                    parsedVal = 'XF55';
                  } else if (parsedVal === 'HFS 60 Silica H') {
                    parsedVal = 'HFS60';
                  } else if (parsedVal === 'XF75') {
                    parsedVal = 'XF75';
                  } else if (parsedVal === 'XF53') {
                    parsedVal = 'XF53';
                  } else if (parsedVal === 'XIGA 40') {
                    parsedVal = 'XF40';
                  } else if (parsedVal === 'Custom') {
                    parsedVal = 'Custom';
                  }
                } else {
                  if (parsedVal === 'Aquaflex 64') {
                    parsedVal = 'XF64';
                  } else if (parsedVal === 'Aquaflex 55') {
                    parsedVal = 'XF55';
                  } else if (parsedVal === 'HFS 60 Silica V') {
                    parsedVal = 'HFS60';
                  } else if (parsedVal === 'XF75') {
                    parsedVal = 'XF75';
                  } else if (parsedVal === 'Aquaflex 40') {
                    parsedVal = 'XF40C';
                  } else if (parsedVal === 'Custom') {
                    parsedVal = 'Custom';
                  }
                }
              }

              if (key === 'all_cap_based_on') {
                if (value === 1) {
                  parsedVal = 'Permeate Production';
                } else if (value === 0) {
                  parsedVal = 'Feed flow';
                }
              }
              projectionInputs[calcValIndex].values = [String(parsedVal)];
            }
          }

          //TODO: PARSE OUTPUTS
          for (const [key, value] of Object.entries(calculationResult.user.outputs)) {
            // Find index of the projectionValue we want to change.
            // const calcValIndexInputs = projectionInputs.findIndex(
            //   (input) => input.calculation_field === key
            // );

            const calcValIndexOutputs = projectionOutputs.findIndex((input) => input.calculation_field === key);

            const calcValIndexInputs = projectionInputs.findIndex((input) => input.calculation_field === key);

            if (projectionInputs[calcValIndexInputs]) {
              let parsedVal: any = String(value);

              if (parseFloat((projectionInputs[calcValIndexInputs].decimals || '-1') as string) >= 0) {
                parsedVal = String(
                  toFixedIfNecessary(
                    String(value),
                    parseFloat((projectionInputs[calcValIndexInputs].decimals || '-1') as string)
                  )
                );
              } else if (projectionInputs[calcValIndexInputs].decimals) {
                parsedVal = String(value);
              }

              if (projectionInputs[calcValIndexInputs].valueOptions?.find((option) => option.id === 'true')) {
                parsedVal = String(value);
              }

              if (projectionInputs[calcValIndexInputs].valueOptions?.find((option) => option.id === 'null')) {
                if (value === '') {
                  parsedVal = String('null');
                }
              }

              projectionInputs[calcValIndexInputs].values = [String(parsedVal)];
            }

            // Update value in our projectionValues array
            if (projectionInputs[calcValIndexInputs]) {
              let parsedVal: any = value;
              if (key === 'all_module_name') {
                if ((projection.record.solution?.id || '') === 'xiga') {
                  if (parsedVal === 'XIGA 64') {
                    parsedVal = 'XF64';
                  } else if (parsedVal === 'XIGA 55') {
                    parsedVal = 'XF55';
                  } else if (parsedVal === 'HFS 60 Silica H') {
                    parsedVal = 'HFS60';
                  } else if (parsedVal === 'XF75') {
                    parsedVal = 'XF75';
                  } else if (parsedVal === 'XF53') {
                    parsedVal = 'XF53';
                  } else if (parsedVal === 'XIGA 40') {
                    parsedVal = 'XF40';
                  } else if (parsedVal === 'Custom') {
                    parsedVal = 'Custom';
                  }
                } else {
                  if (parsedVal === 'Aquaflex 64') {
                    parsedVal = 'XF64';
                  } else if (parsedVal === 'Aquaflex 55') {
                    parsedVal = 'XF55';
                  } else if (parsedVal === 'HFS 60 Silica V') {
                    parsedVal = 'HFS60';
                  } else if (parsedVal === 'XF75') {
                    parsedVal = 'XF75';
                  } else if (parsedVal === 'Aquaflex 40') {
                    parsedVal = 'XF40C';
                  } else if (parsedVal === 'Custom') {
                    parsedVal = 'Custom';
                  }
                }
              }

              if (key === 'all_cap_based_on') {
                if (value === 1) {
                  parsedVal = 'Permeate Production';
                } else if (value === 0) {
                  parsedVal = 'Feed flow';
                }
              }
            }

            // Update value in our projectionValues array
            if (projectionOutputs[calcValIndexOutputs]) {
              let parsedVal = value;

              if (parseFloat((projectionOutputs[calcValIndexOutputs].decimals || '-1') as string) >= 0) {
                parsedVal = String(
                  toFixedIfNecessary(
                    String(value),
                    parseFloat((projectionOutputs[calcValIndexOutputs].decimals || '-1') as string)
                  )
                );
              } else if (projectionOutputs[calcValIndexOutputs].decimals) {
                parsedVal = String(value);
              }

              projectionOutputs[calcValIndexOutputs].values = [parsedVal as string];
            }
          }

          setActiveProcess('All done, redirecting you to the projection');

          if (
            keepDuplicates(Object.keys(calculationInputs), [
              'all_feed_water',
              'all_circulation',
              'all_high_solids',
              'all_solution',
              'all_temperature',
              'all_module_name',
              'all_design_turbidity',
              'all_capacity',
              'all_cap_based_on',
              'chm_alkalinity',
              'chm_ph',
              'all_tds',
            ]).length > 0
          ) {
            onChange('advisedInputs', projectionInputs);
          }

          onChange('inputs', projectionInputs);
          onChange('outputs', projectionOutputs);
          setData(projectionInputs.filter((input) => dataFilterKeys.includes(input.category || '')) || []);
          setRunningCalc(false);
          setSavedRecordState();
          onClose();
        } else {
          setRunningCalc(false);
        }
      }, 500);
    })
    .catch((err) => {
      console.error('projection.runCalculation error', err);
      if (setError) {
        if (typeof err === 'string') {
          setError(err);
        }

        if (
          typeof err === 'object' &&
          err.errors &&
          Array.isArray(err.errors) &&
          err.errors.length > 0 &&
          'message' in err.errors[0]
        ) {
          setError(err.errors[0].message);
        }
      }
      setRunningCalc(false);
    });
};
