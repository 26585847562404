import { MenuItem, TableCell, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { PentairRed } from '../../../config/theme/pentair-theme/colors';
import { CalculationValue } from '../../../v2/feature/solution/types/record';
import { useFormChange } from './hooks/use-form-change';
import { useFormValidation } from './hooks/use-form-validation';
import { DefaultFormFieldProps } from './types';

interface OwnProps extends DefaultFormFieldProps<Partial<CalculationValue>> {
  disabled?: boolean;
  type: string;
  waterSource: string;
  solution: string;
  backwashWaterReuse: boolean;
}

const getMaxValue = (waterSource: string, requestId: string) => {
  const requested = waterSourceMap.find((source) => source.id === waterSource);
  if (requested) {
    return requested[requestId];
  } else {
    return 0;
  }
};

const waterSourceMap = [
  {
    id: 'City water',
    toc: 2,
    cod: 5,
    color: 10,
  },
  {
    id: 'Well water',
    toc: 5,
    cod: 10,
    color: 20,
  },
  {
    id: 'Surface water',
    toc: 50,
    cod: 100,
    color: 500,
  },
  {
    id: 'Sea water',
    toc: 50,
    cod: 100,
    color: 100,
  },
  {
    id: 'Treated effluent',
    //toc: 150,
    //cod: 500,
    //color: 2500,
    toc: 500, // pxx-1236
    cod: 1250,
    color: 6250,
  },
];

interface Validation {
  id: string;
  validations: {
    type: string;
    operator: string;
    value: number;
    error: string;
    condition?: {
      key: string;
      operator: string;
      value: string;
    };
  }[];
}

export const FormTableRowFieldProjectionCalculationValueConvert: React.FC<OwnProps> = (props) => {
  // const [calculationValue, setCalculationValue] = React.useState(
  //   props.value || {}
  // );

  const { value, validations, onError, solution, waterSource, backwashWaterReuse, disabled } = props;

  const calculationValue = value || {};

  const [validationError, setValidationError] = React.useState<string | undefined>();

  const error = useFormValidation(
    (value && value.values && value.values[0]) || null,
    validations || 'required|max:120',
    onError
  );

  const doValidation = React.useCallback(
    (id: string, newValue: string) => {
      const validationsDB: Validation[] = [
        {
          id: 'design_turbidity',
          validations: [
            {
              type: 'number',
              operator: '>',
              value: 100,
              error:
                'Aquaflex solution not feasible for the given water parameters. Please select Aquaflex High Solids (HS) solution',
            },
            {
              type: 'number',
              operator: '>',
              value: 5,
              condition: {
                key: 'water_source',
                operator: '>',
                value: 'City water',
              },
              error: 'Unrealistic calculated turbidity load for City water. Enter realistic feed water parameters',
            },
            {
              type: 'number',
              operator: '<',
              value: 2,
              condition: {
                key: 'water_source',
                operator: '=',
                value: 'Treated effluent',
              },
              error:
                'Unrealistic calculated turbidity load for treated effluent. Enter realistic feed water parameters',
            },
          ],
        },
        {
          id: 'turbidity',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Unrealistic turbidity, should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: 1500,
              error: "Unrealistic turbidity, can't be bigger than 1500",
            },
          ],
        },
        {
          id: 'tss',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: solution === 'aquaflex-hs' ? 3000 : 1500,
              error: 'Unrealistic TSS',
            },
          ],
        },
        {
          id: 'toc',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: getMaxValue(waterSource, 'toc'),
              error: 'Abnormally high TOC for ' + waterSource + ' to have a feasible UF operation.',
            },
          ],
        },
        {
          id: 'cod',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: getMaxValue(waterSource, 'cod'),
              error: 'Abnormally high COD for ' + waterSource + ' to have a feasible UF operation.',
            },
          ],
        },
        {
          id: 'bod',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: 100,
              error: 'Aquaflex HS solution is not feasible. Please select Crossflow UF solution or MBR application',
            },
          ],
        },
        {
          id: 'color',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: getMaxValue(waterSource, 'color'),
              error: 'Abnormally high color for ' + waterSource + ' to have a feasible UF operation.',
            },
          ],
        },
        {
          id: 'temperature',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: 40,
              error: 'Above 40°C or 104°F operation not feasible.',
            },
          ],
        },
        {
          id: 'alcalinity',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: 'Should be bigger than 0',
            },
            {
              type: 'number',
              operator: '>',
              value: 5000,
              error: 'Unrealistic alkakinity',
            },
          ],
        },
        {
          id: 'ph',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: "Can't use value lower than 0",
            },
            {
              type: 'number',
              operator: '>',
              value: 14,
              error: "PH can't be higher than 14",
            },
          ],
        },
        {
          id: 'tds',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: "Can't use value lower than 0",
            },
            {
              type: 'number',
              operator: '>',
              value: 200000,
              error: 'Unrealistic TDS',
            },
          ],
        },
        {
          id: 'hardness',
          validations: [
            {
              type: 'number',
              operator: '<',
              value: 0,
              error: "Can't use value lower than 0",
            },
            {
              type: 'number',
              operator: '>',
              value: 20000,
              error: 'Unrealistic hardness',
            },
          ],
        },
      ];

      const validation = validationsDB.find((validationDB) => validationDB.id === id);

      if (id === 'design_turbidity') {
        if (waterSource === 'City water') {
          if (parseFloat(newValue) > 5) {
            setValidationError(
              'Unrealistic calculated turbidity load for City water. Enter realistic feed water parameters'
            );
            return;
          } else {
            setValidationError(undefined);
          }
        } else if (waterSource === 'Treated effluent') {
          if (parseFloat(newValue) < 2) {
            setValidationError(
              'Unrealistic calculated turbidity load for Treated effluent. Enter realistic feed water parameters'
            );
            return;
          } else {
            setValidationError(undefined);
          }
        } else if (solution === 'xline') {
          if (parseFloat(newValue) > 30) {
            setValidationError(
              'X-line solution is not feasible for the given water parameters. Please select Aquaflex solution.'
            );
            return;
          } else {
            setValidationError(undefined);
          }
        } else if (solution === 'xiga') {
          if (parseFloat(newValue) > 30) {
            setValidationError(
              'Xiga solution is not feasible for the given water parameters. Please select Aquaflex solution.'
            );
            return;
          } else {
            setValidationError(undefined);
          }
        } else if (solution === 'aquaflex-hs') {
          if (backwashWaterReuse && parseFloat(newValue) > 500) {
            setValidationError(
              'Aquaflex HS solution not feasible for the given water parameters. Please select Crossflow UF solution'
            );
            return;
          } else if (!backwashWaterReuse && parseFloat(newValue) > 250) {
            setValidationError(
              'Aquaflex HS solution not feasible for the given water parameters. Please select Crossflow UF solution'
            );
            return;
          } else if (parseFloat(newValue) < 100) {
            setValidationError(
              'Aquaflex HS might be expensive for the given water quality. You may consider the Aquaflex solution.'
            );
            return;
          } else {
            setValidationError(undefined);
          }
        }
        return;
      }

      if (validation && validation.validations.length) {
        if (newValue.length <= 0) {
          setValidationError('Required');
          return;
        }
        const parsedVal = parseFloat(newValue);
        let hasError = false;

        validation.validations.forEach((val) => {
          if ((val.condition && waterSource === val.condition.value) || !val.condition) {
            if (val.type === 'number') {
              if (val.operator === '>=') {
                if (parsedVal >= val.value) {
                  setValidationError(val.error);
                  hasError = true;
                  return;
                } else {
                  if (hasError === false) {
                    setValidationError(undefined);
                  }
                }
                return;
              }
              if (val.operator === '<=') {
                if (parsedVal <= val.value) {
                  setValidationError(val.error);
                  hasError = true;
                  return;
                } else {
                  if (hasError === false) {
                    setValidationError(undefined);
                  }
                }
                return;
              }
              if (val.operator === '>') {
                if (parsedVal > val.value) {
                  setValidationError(val.error);
                  hasError = true;
                  return;
                } else {
                  if (hasError === false) {
                    setValidationError(undefined);
                  }
                }
                return;
              }
              if (val.operator === '=') {
                if (parsedVal === val.value) {
                  setValidationError(val.error);
                  hasError = true;
                  return;
                } else {
                  if (hasError === false) {
                    setValidationError(undefined);
                  }
                }
                return;
              }
              if (val.operator === '<') {
                if (parsedVal < val.value) {
                  setValidationError(val.error);
                  hasError = true;
                  return;
                } else {
                  if (hasError === false) {
                    setValidationError(undefined);
                  }
                }
                return;
              }
            }
          }
        });
      }
    },
    [backwashWaterReuse, solution, waterSource]
  );

  const { onChange, changed } = useFormChange((e) => {
    const newCalcValue = calculationValue;
    if (e.target.id === newCalcValue.id) {
      doValidation(e.target.id, e.target.value);
      newCalcValue.values = [e.target.value];
      // Since user is modifying this input, we will set the isSUggested value to false
      newCalcValue.isSuggested = false;
    } else {
      newCalcValue.unit = e.target.value;
    }
    // setCalculationValue({ ...newCalcValue });
    return calculationValue;
  }, props.onChange);

  React.useEffect(() => {
    if (calculationValue.id === 'design_turbidity') {
      if (
        solution === 'xline' &&
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) > 30
      ) {
        setValidationError(
          'X-line solution is not feasible for the given water parameters. Please select Aquaflex solution.'
        );
      } else if (
        solution === 'xiga' &&
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) > 30
      ) {
        setValidationError(
          'Xiga solution is not feasible for the given water parameters. Please select Aquaflex solution.'
        );
      } else if (
        ['aquaflex', 'aquaflex-new'].includes(solution) &&
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) > 100
      ) {
        setValidationError(
          'Aquaflex solution not feasible for the given water parameters. Please select Aquaflex High Solids (HS) solution'
        );
      } else if (
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) < 2 &&
        waterSource === 'Treated effluent'
      ) {
        setValidationError(
          'Unrealistic calculated turbidity load for treated effluent. Enter realistic feed water parameters'
        );
      } else if (
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) > 5 &&
        waterSource === 'City water'
      ) {
        setValidationError(
          'Unrealistic calculated turbidity load for city water. Enter realistic feed water parameters'
        );
      } else if (
        solution === 'aquaflex-hs' &&
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) < 100
      ) {
        setValidationError(
          'Aquaflex HS might be expensive for the given water quality. You may consider the Aquaflex solution.'
        );
      } else if (
        solution === 'aquaflex-hs' &&
        backwashWaterReuse === false &&
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) > 250
      ) {
        setValidationError(
          'Aquaflex HS solution not feasible for the given water parameters. Please select Crossflow UF solution'
        );
      } else if (
        solution === 'aquaflex-hs' &&
        backwashWaterReuse === true &&
        calculationValue &&
        calculationValue.values &&
        Number(calculationValue.values[0]) > 500
      ) {
        setValidationError(
          'Aquaflex HS solution not feasible for the given water parameters. Please select Crossflow UF solution'
        );
      } else {
        setValidationError(undefined);
      }

      onError && onError(validationError || false);
    }
  }, [calculationValue, calculationValue.values, solution, waterSource]);

  React.useEffect(() => {
    doValidation(
      calculationValue.id || '',
      (calculationValue && calculationValue.values && (calculationValue.values[0] as string)) || ''
    );
    if (onError) {
      if (validationError) {
        onError(validationError);
      } else {
        onError('');
      }
    }
  }, [waterSource]);

  React.useEffect(() => {
    if (onError) {
      onError(validationError || false);
    }
  }, [validationError, calculationValue]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          // padding="none"
          sx={
            (((changed && error) ||
              (changed && validationError === 'Required') ||
              (validationError && validationError !== 'Required')) && {
              color: PentairRed[500],
              border: 'none',
            }) || { border: 'none' }
          }
        >
          <Typography paddingTop={0.25} color={disabled ? 'softGray.900' : 'inherit'} fontSize={'inherit'}>
            {calculationValue.name}*
          </Typography>
        </TableCell>
        <TableCell padding="none" sx={{ paddingLeft: 1.33, border: 'none' }}>
          <TextField
            type={'number'}
            disabled={disabled}
            required
            fullWidth
            margin="none"
            name={calculationValue.id}
            id={calculationValue.id}
            inputProps={{ maxLength: 121 }}
            value={(calculationValue.values && calculationValue.values[0]) || null}
            onChange={onChange}
            error={changed && error ? true : false || (changed && validationError) ? true : false}
            helperText={(changed && error && '') || (validationError && '')}
            sx={{ backgroundColor: calculationValue.isSuggested ? '#fcf7e8' : '#fff' }}
          />
        </TableCell>
        <TableCell padding="none" sx={{ paddingLeft: 1.33, border: 'none' }}>
          {calculationValue.unitOptions && calculationValue.unitOptions.length > 1 && (
            <TextField
              select
              fullWidth
              disabled
              required
              margin="none"
              name={calculationValue.id + 'unit'}
              id={calculationValue.id + 'unit'}
              value={calculationValue.unit || calculationValue.unitOptions[0]}
              onChange={onChange}
              error={changed && error ? true : false || (changed && validationError) ? true : false}
              helperText={(changed && error && '') || (validationError && '')}
            >
              {calculationValue.unitOptions.map((unitOption) => (
                <MenuItem key={unitOption} value={unitOption}>
                  {unitOption}
                </MenuItem>
              ))}
            </TextField>
          )}
          {calculationValue.unitOptions && calculationValue.unitOptions.length === 1 && (
            <TextField
              fullWidth
              disabled
              required
              margin="none"
              name={calculationValue.id + 'unit'}
              id={calculationValue.id + 'unit'}
              value={calculationValue.unit || calculationValue.unitOptions[0]}
              onChange={onChange}
              error={changed && error ? true : false || (changed && validationError) ? true : false}
              helperText={(changed && error && '') || (validationError && '')}
            />
          )}
        </TableCell>
      </TableRow>
      {((changed && error) ||
        (changed && validationError === 'Required') ||
        (validationError && validationError !== 'Required')) && (
        <TableRow style={{ borderBottom: 'none' }}>
          <TableCell colSpan={4} style={{ paddingTop: '0px', marginTop: '-8px' }}>
            <span style={{ color: PentairRed[500], fontSize: '0.8rem' }}>{validationError || error}</span>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
